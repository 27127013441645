import React from "react";

import { Typography, Container } from "@mui/material";

function NotFound() {
  return (
    <>
      <Container>
        <Typography variant="h1" align="center" style={{ marginTop: "5vh" }}>
          404
        </Typography>
        <Typography variant="h5" align="center" style={{ marginTop: "2vh" }}>
          Page Not Found
        </Typography>
      </Container>
    </>
  );
}

export default NotFound;
