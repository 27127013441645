import React from 'react'

import { Typography, Container, Box } from '@mui/material';

function Contact() {
  return (
    <>
     <Box
        sx={{
          width: '100%',
          borderRadius: 1,
          backgroundImage: 'linear-gradient(to top, #0D3C42, #367467)',
          paddingTop: '3vh',
          paddingBottom: '6vh',
          align: "center"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff', // Text color
          }}
        >
          <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 30}}>
            Contact Us
          </Typography>
        </Box>
      </Box>
      <Container style={{ marginTop: '3vh' }}>

        <Typography variant="body1">
          You can reach us via social media:
          <ul>
            <li>Instagram: <a href="https://www.instagram.com/eshatrojan/" target="_blank" rel="noreferrer">@eshatrojan</a></li>
            <li>Facebook: <a href="https://facebook.com/eshatrojan" target="_blank" rel="noreferrer">@eshatrojan</a></li>
            <li>LinkedIn: <a href="https://linkedin.com/company/esha-trojan" target="_blank" rel="noreferrer">E.S.H.A. Trojan</a></li>
            <li>Discord: <a href="https://discord.gg/RMAQDWbKTF" target="_blank" rel="noreferrer">E.S.H.A. Trojan</a></li>
            <li>WhatsApp: <a href="https://chat.whatsapp.com/Eb0rKMsyy5cF5iOPafEJ9I" target="_blank" rel="noreferrer">E.S.H.A. Trojan</a></li>
          </ul>
          Or at the following email addresses:
          <ul>
            <li>Chairman: <a href="mailto:chair@eshatrojan.nl">chair@eshatrojan.nl</a></li>
            <li>Secretary: <a href="mailto:secretary@eshatrojan.nl">secretary@eshatrojan.nl</a></li>
            <li>Treasurer: <a href="mailto:treasurer@eshatrojan.nl">treasurer@eshatrojan.nl</a></li>
          </ul>
        </Typography>
      </Container>
    </>
  );
}

export default Contact;
