import * as React from 'react';
import { Box, Typography, Link } from '@mui/material';

export default function Job() {
    return (
        <>
      {/* <Box
        bgcolor='#367467'
        sx={{
          width: '30%',
          borderRadius: 1,
          paddingTop: '1vh',
          paddingBottom: '3vh',
          color: '#fff',
        }}
      >
        <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 24}}>
            h4x3r @ Tr0j4n
        </Typography>

        <Typography variant="body1" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 18, color: "#dbdbdb"}}>
          Offered By: ESHA Trojan<br/>
          Location: Eindhoven<br/>
          Experience Level: 1337 😎
        </Typography>

        <Link href="#" underline="hover">
        <Typography variant="body1" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 18, color: "#dbdbdb"}}>
            More Details
        </Typography>
        </Link>

      </Box> */}

      
      <Box
        bgcolor='#fff'
        sx={{
          width: "400px",
          borderRadius: 1,
          paddingTop: '1vh',
          paddingRight: "2vh",
          paddingLeft: "2vh",
          paddingBottom: '3vh',
        }}
      >
        <Typography variant="h6" align="center" style={{ fontFamily: 'monospace', fontSize: 24}}>
        <img src="/images/jobs/innod_logo.png" align="center" style={{ height: '12vh', marginTop: '20px'}} alt="Job logo"/>
        </Typography>

        <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 24}}>
        Cybersecurity Consultant (Student)
        </Typography>

        <Typography variant="body1" align="center" style={{ fontFamily: 'monospace', fontSize: 20}}>
          @ Innovation Dynamics BV
        </Typography>

        <Typography variant="body1" align="center" style={{padding: '1vh', fontFamily: 'monospace', fontSize: 16}}>
          Location: Eindhoven (Flexible)<br/>
          Experience Level: 1337 😎
        </Typography>

        <Link href="/job-details-innod">
        <Typography variant="body1" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 18}}>
            More Details
        </Typography>
        </Link>

      </Box>

    </>
  );
}