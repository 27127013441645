import React from "react";

import { Typography, Container, Box, Button } from "@mui/material";

function Privacy() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: 1,
          backgroundImage: "linear-gradient(to top, #0D3C42, #367467)",
          paddingTop: "3vh",
          paddingBottom: "6vh",
          align: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff", // Text color
          }}
        >
          <Typography
            variant="h6"
            align="center"
            style={{ padding: "1vh", fontFamily: "monospace", fontSize: 30 }}
          >
            Privacy Policy
          </Typography>
        </Box>
      </Box>
      <Container style={{ marginTop: "5vh" }}>
        <Typography variant="h5" style={{ marginBottom: "2vh" }}>
          English Version
        </Typography>

        <object
          data="https://drive.google.com/file/d/1701vRe-fOem51F1jgMBRqCUjIOSqAvHv/preview"
          type="application/pdf"
          width="100%"
          height="1000vh"
        >
          <Button
            variant="contained"
            color="primary"
            href="https://drive.google.com/file/d/1701vRe-fOem51F1jgMBRqCUjIOSqAvHv/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open
          </Button>
        </object>

        <Typography
          variant="h5"
          style={{ marginTop: "5vh", marginBottom: "2vh" }}
        >
          Dutch Version
        </Typography>

        <object
          data="https://drive.google.com/file/d/12R8eGKEGlTiHf8HViakv7haNO_TZfrWn/preview"
          type="application/pdf"
          width="100%"
          height="1000vh"
          align="center"
        >
          <Button
            variant="contained"
            color="primary"
            href="https://drive.google.com/file/d/12R8eGKEGlTiHf8HViakv7haNO_TZfrWn/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open
          </Button>
        </object>
      </Container>
    </>
  );
}

export default Privacy;
