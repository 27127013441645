import React from 'react'
import {Box, Link, Typography, Container} from '@mui/material';

function JobDetail({job_title, job_description, job_contact_name, job_contact_email, job_logo, job_company, job_location, job_website}) {
    return (
      <>
        <Box
        sx={{
          width: '100%',
          borderRadius: 1,
          paddingBottom: '3vh',
        }}>

            <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 24}}>
            <img src={job_logo} style={{ height: '10vh', marginRight: '20px', marginTop: '20px'}} alt="Job logo"/>
            </Typography>
            
            <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 24}}>
                {job_title}
            </Typography>

            <Typography variant="body1" align="center" style={{ fontFamily: 'monospace', fontSize: 18}}>
            {job_company}
            </Typography>
            
            <Typography variant="body1" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 18}}>
            <b>Location:</b><br/> {job_location}
            </Typography>

            <Typography variant="body1" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 18}}>
            <b>Contact:</b><br/> {job_contact_name}
            </Typography>

            {/* Need to convert {job_contact_email} to string. Hardcoded mail for now... */}
            <Link href={"mailto:j.vogels@innovation-dynamics.com"}>
            <Typography variant="body1" align="center" style={{fontFamily: 'monospace', fontSize: 16}}>
                {job_contact_email}<br/>
            </Typography>
            </Link>
            <Link href={"https://innovation-dynamics.com/"}>
            <Typography variant="body1" align="center" paddingTop={"5px"} style={{ fontFamily: 'monospace', fontSize: 16}}>
            {job_website}
            </Typography>
            </Link>

        </Box>
        <Box
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  paddingTop: '20px',
                  paddingBottom: '3vh',
                }}
                >
                    
                    {job_description}
        </Box>
        </>
    );
}


function JobDetailsInnoD() {
  return (
    <Container style={{ marginTop: '3vh' }}>
      <Box 
      sx={{
        width: '100%',
        borderRadius: 1,
        paddingTop: '1vh',
        paddingBottom: '3vh',
      }}
      >
        <JobDetail
        job_title="Cybersecurity Consultant (Student)"
        job_company="Innovation Dynamics BV"
        job_location="Eindhoven (Flexible)"
        job_contact_name="Joris Vogels, +31 6 461 048 25"
        job_contact_email="j.vogels@innovation-dynamics.com"
        job_logo="/images/jobs/innod_logo.png"
        job_website="innovation-dynamics.com"
        job_description={
            <Container style={{ marginTop: '3vh' }}>
    
            <Typography variant="body1" style={{fontFamily: 'monospace', fontSize: 16}}>
            <b>Who are we?</b><br/><br/>
Innovation Dynamics BV is a leading innovator in IT Security, Compliance, and Business Services. Our
mission is to be a trusted partner for companies navigating the complexities and risks of today’s IT
security and compliance landscape, allowing customers to focus on driving business innovation and
staying competitive.<br/><br/>
Our <b>IT security</b> services range from foundational security-awareness training, vulnerability
assessments, and policy implementation to advanced penetration testing and full-scale ISO 27001
certification.<br/><br/>
Our <b>compliance</b> services are grounded in deep expertise within the highly regulated medical device
industry. Our specialists have extensive experience with ISO 13485 and MDSAP standards, supporting
clients from startups to some of the world’s largest healthcare companies. We ensure smooth
certification processes, mitigate regulatory risks, and help clients meet the most stringent industry
standards.<br/><br/>
Our <b>business</b> services include investment readiness support, go-to-market consulting, strategic
business reviews, and due diligence tailored for venture capital and private equity firms. Additionally,
we provide hands-on interim executive leadership to ensure smooth operational transitions. Our goal is
to equip businesses with the insights and leadership they need to thrive at every stage of growth.
Innovation Dynamics is a young, ambitious, and fast-growing company. Our diverse, international team
is highly skilled, complementary, and reliable—while also being fun to work with! As we continue to grow,
we are seeking talented professionals to join us on our exciting journey.<br/><br/>
<b>What Do We Offer?</b><br/><br/>
At Innovation Dynamics, we maintain a strong customer-centric focus, delivering solutions that are
proactive, pragmatic, and tailored to meet our clients’ needs. As a Cybersecurity Consultant, you will
work closely with management to support the sales process, providing calm, competent, and reliable
guidance that helps clients feel secure in their decision-making. To recognize and reward your
contribution, we offer a competitive hourly salary, and the potential to grow with the business.
With your ambitious, entrepreneurial mindset, you’ll contribute directly to the growth of our IT Security
business. Upon demonstrating success over a period of 2-3 years, you may have the opportunity to
obtain an equity stake in the company you help build.<br/><br/>
<b>Tasks and responsibilities</b><br/><br/>
<u>Support Sales and Collaborate with Clients:</u><br/>
- Collaborate with management in customer meetings and presentations, offering technical insights
and helping tailor solutions to customer needs<br/>
- Engage with management to present cybersecurity solutions to potential customers, showcasing the
value and impact of your services<br/>
- Assist in creating proposals, presentations, and technical documentation to support the sales
process and close deals<br/>
- Work closely with customers and internal stakeholders to understand their security needs and
design custom solutions<br/>
Provide Security Awareness Training:<br/>
- Deliver security-awareness training programs to customers, ensuring their teams understand and
adhere to best practices in cybersecurity<br/>
- Tailor and improve training programs to meet the unique needs and compliance requirements of
each customer<br/><br/>
<u>Conduct Security Assessments:</u><br/>
- Perform vulnerability assessments, penetration testing, and security audits to identify potential
threats and weaknesses in customer systems<br/>
- Analyze security risks and provide actionable recommendations to improve overall security posture
Hands-On Security Implementation:<br/>
- Deploy and maintain firewalls, IDS/IPS, endpoint security, and other critical security infrastructure
for customers<br/>
- Ensure effective logging, monitoring, and alerting systems are in place to detect and respond to
potential security threats<br/>
Monitor and Respond to Security Incidents:<br/>
- Proactively monitor security systems and networks for potential threats or breaches<br/>
- Investigate, document, and respond to security incidents, performing root cause analysis and
recommending mitigation strategies<br/><br/>
<u>Stay Updated on Cybersecurity Trends and Grow the Cyber business:</u><br/>
- Continuously stay informed of the latest cybersecurity threats, trends, and technologies<br/>
- Research and recommend new security products, technologies, or methods to improve existing
services<br/>
- Work closely with management to develop strategies for expanding Innovation Dynamics'
cybersecurity offering<br/><br/>
<b>Who are we looking for:</b><br/><br/>
Are you a technical university student with a passion for (ethical) hacking and cybersecurity? Do you
have a calm yet ambitious demeanor, with the potential to shine as the technical expert in client
meetings where management leads the conversation?<br/><br/>
We’re seeking someone currently pursuing a bachelor’s or master’s degree in an ICT-related field, who
has been actively involved in the cyber space from a young age.<br/><br/>
You should have excellent English skills (Dutch is a clear advantage, but not required), both written and
verbal. Occasional travel within the Netherlands will be part of the role.<br/><br/>
At Innovation Dynamics, we understand that your education is a priority. We are committed to helping
you stay on track by providing the flexibility you need to balance your studies with your role here.
We highly value individuals who possess a profound enthusiasm for technology and cybersecurity. If
you’re someone who immerses themselves in these fields with great dedication and curiosity, we fully
respect and embrace this passion.<br/><br/>
At our company, trustworthiness and adherence to ethical standards are fundamental to our operations.
We expect all team members to demonstrate 100% reliability and to consistently represent both our
company’s and our customers' interests with the highest level of integrity. To support you in meeting
these expectations, we provide regular training on ethical practices and offer ongoing feedback. Our
commitment to maintaining these standards is integral to our success and your professional growth.
Our diverse team thrives on unique perspectives, and as long as you bring your skills and enthusiasm to
the table, we’re excited to have you join us!<br/><br/>
<b>Interested?</b><br/><br/>
Send your comprehensive CV to: <a href="mailto:j.vogels@innovation-dynamics.com">j.vogels@innovation-dynamics.com</a><br/>
For more information please contact Joris Vogels on +31 6 461 048 25
            </Typography>
          </Container>
        }
        />
      </Box>
      </Container>
  );
}

export default JobDetailsInnoD;
