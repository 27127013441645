import React from 'react'

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, Outlet } from "react-router-dom";

import Footer from './common/Footer';
import Header from './common/Header';
import defaultTheme from './common/theme';
import Home from './pages/Home';
// import About from './pages/About';
import Member from './pages/Member';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
// import TrojanCTF from './pages/TrojanCTF';
import NotFound from './pages/NotFound';
// import JobDetailsTA from './pages/JobDetailsTA';
import Privacy from './pages/Privacy';
import JobDetailsInnoD from './pages/JobDetailsInnoD';

// TODO add security.txt, robots.txt, favicon.ico

// function App() {
//   const [data, setData] = React.useState("Loading...")

//   React.useEffect(() => {
//     fetch('/api')
//       .then(res => res.json())
//       .then((data) => setData(data.message))
//   }, [])

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <CssBaseline />
//       <Header />

//       <main>
//         <Typography variant="p">
//           Testing {data}
//         </Typography>
//       </main>

//       <Footer>
//         <p>Test123!</p>
//       </Footer>
//     </ThemeProvider>
//   )
// }

// TODO fix navigation buttons on mobile
function App() {
 return (
  <Routes>
    <Route exact path="/" element={<Layout />}>
      <Route index element={<Home />} />
      {/* <Route path="about" element={<About />} /> */}
      <Route path="contact" element={<Contact />} />
      <Route path="careers" element={<Careers />} />
      <Route path="member" element={<Member />} />
      <Route path="privacy" element={<Privacy />} />
      {/* <Route path="job-details-ta" element={<JobDetailsTA />} /> */}
      <Route path="job-details-innod" element={<JobDetailsInnoD />} />
      {/* <Route path="trojanctf" element={<TrojanCTF />} /> */}
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
 );
}

function Layout() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />

      <Footer />
    </ThemeProvider>
  );
}

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

export default App;
