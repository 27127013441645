import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function PrivacyPolicy() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
    <Link color="inherit" href="/privacy">Privacy Policy</Link>
    </Typography>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        E.S.H.A. Trojan
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <br />
      {"Logo designed by Ula Nina Kunigėlytė."}
    </Typography>
  );
}

export default function Footer({ children }) {
  return (
    <Box sx={{ padding: 6 }} component="footer">
      {children}
      <Copyright />
      <PrivacyPolicy />
    </Box>
  );
}
