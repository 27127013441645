import * as React from 'react';
import Job from "./Job"

export default function Jobs() {
  return (
    <>
     <div class="row" style={{float: "center", width: "100%", display: "flex", align: "center", justifyContent: "center"}}>
        <Job />
     </div> 
    </>
  );
}
