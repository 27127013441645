import React from "react";

import { Typography, Box, Container } from "@mui/material";

function Member() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: 1,
          backgroundImage: "linear-gradient(to top, #0D3C42, #367467)",
          paddingTop: "3vh",
          paddingBottom: "6vh",
          align: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff", // Text color
          }}
        >
          <Typography
            variant="h6"
            align="center"
            style={{ padding: "1vh", fontFamily: "monospace", fontSize: 30 }}
          >
            Become a member!
          </Typography>
        </Box>

        <br />
        <Typography
          align="center"
          style={{
            paddingTop: "1vh",
            paddingBottom: "1vh",
            fontFamily: "monospace",
            fontSize: 20,
            color: "#fff",
          }}
        >
          Fill in the form below to become a member.
          <br />
        </Typography>
      </Box>
      <Container>
        <iframe
          title="Become a member"
          src="https://docs.google.com/forms/d/e/1FAIpQLSd27JSF_udPeDcoGrKBPiaQRjmOdAJcfeBFteY0d5u9FTxc8Q/viewform?embedded=true"
          width="100%"
          height="1900px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </Container>
    </>
  );
}

export default Member;
