import React from 'react'

import Jobs from "../common/Jobs/Jobs"
import { Box, Typography } from '@mui/material';

function Careers() {
  return (
      <Box
        sx={{
          width: '100%',
          borderRadius: 1,
          backgroundImage: 'linear-gradient(to top, #0D3C42, #367467)',
          paddingTop: '3vh',
          paddingBottom: '6vh',
          align: "center"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff', // Text color
          }}
        >
          <Typography variant="h6" align="center" style={{ padding: '1vh', fontFamily: 'monospace', fontSize: 30}}>
            Job Board
          </Typography>
        </Box>
        
        <br />
        <Typography align="center" style={{ paddingTop: '1vh', paddingBottom: '1vh' , fontFamily: 'monospace', fontSize: 20, color: '#fff' }}>
          On and off-campus jobs for our more experienced members.<br/>
          More jobs coming soon...<br/><br/>
        </Typography>

        <Jobs />
      </Box>
  );
}

export default Careers;
