import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0D3C42'
    },
    secondary: {
      main: '#367467'
    },
  },
});

export default defaultTheme;
