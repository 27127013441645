import React from "react";

import { Typography, Container, Divider, Button } from "@mui/material";

function Home() {
  return (
    <>
      <img
        src="/images/banner.jpg"
        alt="Banner"
        style={{ width: "100%", height: "40vh", objectFit: "cover" }}
      />

      <Container>
        <Typography variant="subtitle1" fontStyle={"italic"}>
          The CTF association at TU/e.
        </Typography>

        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          The first General Members Meeting (GMM)
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          As a member of E.S.H.A. Trojan you have been invited to the General
          Members Meeting (GMM).
          <br />
          <br />
          During this meeting the attached agenda will be discussed as well as
          the attached documents. If you can't be present but do want to have a
          say, then please contact the <a href="mailto:secretary@eshatrojan.nl">secretary</a> in
          advance. This is a really good opportunity to get to know the
          association and what we are up to, so we hope to see you there!
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <i>Only for registered members!</i>
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Date:</b> December 4th (Wednesday)
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Location:</b> Neuron 0.116
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Schedule:</b> <br />
          18:00 - 20:00 <br />
        </Typography>

        <Divider
          variant="middle"
          style={{ marginTop: "3vh", marginBottom: "3vh" }}
        />

        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          Break The Game: Trojan x Totem Game Dev
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          Have you ever cheated (allegedly) in a game or have you ever used a
          cheat engine? Have you ever wondered how those cheats were actually
          developed or figured out?
          <br />
          <br />
          In that case, this is the event for you. E.S.H.A. Trojan and Totem
          Game Dev are collaborating in order to bring you a workshop where you
          will be able to learn how to break video games. This will be shown on
          a game of our own, Observe. During the workshop you will actually get
          to try to break the game and cheat your way through the game! 😮🎮🤫
          {/* 
          <ul>
            <li>
              🔐 <b>How to gain access</b> - Learn the real-world methods used
              to infiltrate networks and systems
            </li>
            <li>
              🌐 <b>Sniffing & Poisoning</b> - Uncover how attackers intercept
              and manipulate traffic
            </li>
            <li>
              🔍 <b>Service Misconfigurations</b> - Discover how to exploit
              common service vulnerabilities with ease
            </li>
            <li>
              🚨 <b>CVEs</b> - Understand how known vulnerabilities are
              leveraged in attacks
            </li>
            <li>
              💥 <b>Command Injection, SQL Injection, XSS, ...</b> - Creative
              exploitation of web-apps
            </li>
          </ul> */}
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <i>Bring your laptop</i> 💻{" "}
          <i>
            and your curiosity, we'll provide the rest! No prior experience
            needed, this workshop is perfect for beginners who want to take the
            first step toward mastering cybersecurity. Spaces are limited!
            Secure your spot now by filling out the participation form below!
          </i>
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Date:</b> December 5th (Thursday)
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Location:</b> Neuron -1.350
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Schedule:</b> <br />
          17:30 - 19:30 <br />
        </Typography>

        <Button
          target="_blank"
          href="https://forms.gle/EztdWXDBZCsMgqfq6"
          rel="noreferrer"
          variant="contained"
          style={{ marginTop: "2vh" }}
        >
          SIGN UP
        </Button>

        <Divider
          variant="middle"
          style={{ marginTop: "3vh", marginBottom: "3vh" }}
        />

        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          Goals & Vision
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          In our eyes, CTFs are one of the best aspects of cybersecurity. They
          provide a great way to learn, but can also be described as a purely
          competitive sport, thereby providing value for individuals of all
          skill levels in the field. Not only that, but they also bring people
          together, which is a priceless quality in any technical environment.
          <br />
          <br />
          The board of E.S.H.A. Trojan not only aims to establish a medium
          through which people of any background can start participating in
          CTFs, but also to create a thriving community of hackers to share
          experiences with.
          <br />
          <br />
          We have subdivided this vision into four separate goals:
          <ol>
            <li>
              Setting up structural training aimed to improve your ability to
              solve CTF challenges
            </li>
            <li>
              Providing opportunities for members to participate in CTFs and
              making them more accessible as a whole
            </li>
            <li>
              Finding ways to connect people that share a passion for hacking
            </li>
            <li>Organizing a large scale annual CTF of our own</li>
          </ol>
          We wish to foster this community and cannot wait to see it grow. In
          the rest of our policy plan, we will convince you on how we will
          achieve these goals. But more importantly, we hope to persuade you to
          join E.S.H.A. Trojan.
          <br />
          <br />
          Sincerely,
          <br />
          The 0th Board of E.S.H.A. Trojan
        </Typography>
      </Container>
    </>
  );
}

export default Home;
